::-webkit-scrollbar {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

.sticky-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  z-index: 1000;
}

.table-container {
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 500px; /* Set a max height as needed */
}

.sticky-header th {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  background-color: white; /* Ensure background color matches your design */
  z-index: 1000; /* Ensure it appears above other elements */
}
