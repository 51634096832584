.chart-container {
  background-color: white; /* Light background color for the chart container */
  border-radius: 10px; /* Rounded corners for the chart container */
  padding: 20px; /* Padding around the chart */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.canvas {
  border-radius: 10px; /* Rounded corners for the canvas */
}
