.chart-container-pricing-detail {
    position: relative;
    height: 500px; /* You can change this value as needed */
    width: 100%;
    border-radius: 10px;
    /* background-color: aqua; */
  
  }
  

  .chart-container-pricing-detail .rounded223 {
    border-radius: 10px;
  }
  
  .chart-container-pricing-detail canvas {
    /* background: #f91414; */
    border-radius: 10px;
    padding: 10px;
    width: 75vw;
  }
  