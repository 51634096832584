@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "DM Sans", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.shiny-text {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6);
}

.triangle-indicator {
  position: absolute;
  top: 20px; /* Position the triangle vertically in line with the submenu */
  left: -10px; /* Position the triangle left to align with the submenu */
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #173564; /* Same color as submenu background */
}
